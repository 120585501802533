import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import config from 'react-reveal/globals'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import SEO_VARIABLES from '_config/seo-variables'
import { Navbar, Footer, SEO, ViewportObserver } from '_organisms'
import {
  ProudCases,
  RaimanaAbout,
  RaimanaOpportunity,
  RaimanaUber,
  RaiamanaProduct,
  RaimanaView,
  RaimanaMarketplace,
  RaimanaTech,
  RaimanaFinalPhase,
  RaimanaResults,
} from '_molecules'
import { AppContext } from '_context'
import variables from '_config/css-variables'

import styles from './styles.module.css'

const RaimanaPortfolio = ({
  proudCases,
  pageTitle,
  pageDescription,
  pageBackgroundImage,
  navbarColorOverride,
  aboutDescription,
  aboutImage,
  opportunityDescription,
  opportunityImage,
  uberTitle,
  uberDescription,
  uberImage,
  productTitle,
  productDescription,
  productImage,
  viewDescription,
  viewImage,
  marketplaceTitle,
  marketplaceDescription,
  marketplaceImage,
  techTitle,
  techDescription,
  techImage,
  finalPhaseTitle,
  finalPhaseDescription,
  finalPhaseImage,
  titleResults,
  firstDescription,
  secondDescription,
  thirdDescription,
}) => {
  const { setNavbarDefaults } = useContext(AppContext)
  const { setNavbarColor, setNavbarLight } = useContext(AppContext)
  const [y, setY] = useState(0)
  config({ ssrFadeout: true })
  useScrollPosition(
    ({ currPos }) => {
      setY(currPos.y * -1)
    },
    [],
    null,
    false,
    300
  )

  useEffect(() => {
    return setNavbarDefaults
  }, [setNavbarDefaults])

  return (
    <div>
      <Navbar navbarColorOverride={navbarColorOverride} opacityBg />
      <SEO
        title={pageTitle || SEO_VARIABLES.title}
        description={pageDescription || SEO_VARIABLES.description}
        thumbnail={pageBackgroundImage}
      />
      <section className={styles.display}>
        <RaimanaAbout aboutDescription={aboutDescription} aboutImage={aboutImage} />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['raimana-blue-dark'])
            setNavbarLight(false)
          }}
        />
      </section>
      <section id="opportunity" className={styles.display}>
        <RaimanaOpportunity
          opportunityDescription={opportunityDescription}
          opportunityImage={opportunityImage}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['raimana-blue-light'])
            setNavbarLight(true)
          }}
        />
      </section>
      <section className={styles.display}>
        <RaimanaUber
          uberTitle={uberTitle}
          uberDescription={uberDescription}
          uberImage={uberImage}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['raimana-green-light'])
            setNavbarLight(true)
          }}
        />
      </section>
      <section className={styles.display}>
        <RaiamanaProduct
          productTitle={productTitle}
          productDescription={productDescription}
          productImage={productImage}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-white'])
            setNavbarLight(true)
          }}
        />
      </section>
      <section className={styles.display}>
        <RaimanaView viewDescription={viewDescription} viewImage={viewImage} />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['raimana-blue-light'])
            setNavbarLight(true)
          }}
        />
      </section>
      <section className={styles.display}>
        <RaimanaMarketplace
          marketplaceTitle={marketplaceTitle}
          marketplaceDescription={marketplaceDescription}
          marketplaceImage={marketplaceImage}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['raimana-gray-dark'])
            setNavbarLight(false)
          }}
        />
      </section>
      <section className={styles.display}>
        <RaimanaTech
          techTitle={techTitle}
          techDescription={techDescription}
          techImage={techImage}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['raimana-blue-dark'])
            setNavbarLight(false)
          }}
        />
      </section>
      <section className={styles.display}>
        <RaimanaFinalPhase
          finalPhaseTitle={finalPhaseTitle}
          finalPhaseDescription={finalPhaseDescription}
          finalPhaseImage={finalPhaseImage}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['raimana-gray-dark'])
            setNavbarLight(false)
          }}
        />
      </section>
      <section className={styles.display}>
        <RaimanaResults
          titleResults={titleResults}
          firstDescription={firstDescription}
          secondDescription={secondDescription}
          thirdDescription={thirdDescription}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['raimana-blue-dark'])
            setNavbarLight(false)
          }}
        />
      </section>
      <ProudCases currentProject="Raimana" caseStudies={proudCases} extendedCTA />
      <Footer />
    </div>
  )
}
RaimanaPortfolio.propTypes = {
  proudCases: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pageTitle: PropTypes.string.isRequired,
  pageDescription: PropTypes.string.isRequired,
  pageBackgroundImage: PropTypes.string.isRequired,
  aboutDescription: PropTypes.string.isRequired,
  aboutImage: PropTypes.shape({
    description: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      sizes: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcSetWebp: PropTypes.string,
      srcWebp: PropTypes.string,
    }),
  }).isRequired,
  navbarColorOverride: PropTypes.string,
  opportunityImage: PropTypes.shape({
    description: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      sizes: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcSetWebp: PropTypes.string,
      srcWebp: PropTypes.string,
    }),
  }).isRequired,
  opportunityDescription: PropTypes.string.isRequired,
  uberTitle: PropTypes.string.isRequired,
  uberDescription: PropTypes.string.isRequired,
  uberImage: PropTypes.shape({
    description: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      sizes: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcSetWebp: PropTypes.string,
      srcWebp: PropTypes.string,
    }),
  }).isRequired,
  productTitle: PropTypes.string.isRequired,
  productDescription: PropTypes.string.isRequired,
  productImage: PropTypes.shape({
    description: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      sizes: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcSetWebp: PropTypes.string,
      srcWebp: PropTypes.string,
    }),
  }).isRequired,
  viewDescription: PropTypes.string.isRequired,
  viewImage: PropTypes.shape({
    description: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      sizes: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcSetWebp: PropTypes.string,
      srcWebp: PropTypes.string,
    }),
  }).isRequired,
  marketplaceTitle: PropTypes.string.isRequired,
  marketplaceDescription: PropTypes.string.isRequired,
  marketplaceImage: PropTypes.shape({
    description: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      sizes: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcSetWebp: PropTypes.string,
      srcWebp: PropTypes.string,
    }),
  }).isRequired,
  techTitle: PropTypes.string.isRequired,
  techDescription: PropTypes.string.isRequired,
  techImage: PropTypes.shape({
    description: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      sizes: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcSetWebp: PropTypes.string,
      srcWebp: PropTypes.string,
    }),
  }).isRequired,
  finalPhaseTitle: PropTypes.string.isRequired,
  finalPhaseDescription: PropTypes.string.isRequired,
  finalPhaseImage: PropTypes.shape({
    description: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      sizes: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcSetWebp: PropTypes.string,
      srcWebp: PropTypes.string,
    }),
  }).isRequired,
  titleResults: PropTypes.string.isRequired,
  firstDescription: PropTypes.string.isRequired,
  secondDescription: PropTypes.string.isRequired,
  thirdDescription: PropTypes.string.isRequired,
}
RaimanaPortfolio.defaultProps = {
  navbarColorOverride: undefined,
}
export default RaimanaPortfolio
