import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import config from 'react-reveal/globals'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import classNames from 'classnames'

import SEO_VARIABLES from '_config/seo-variables'
import { Navbar, Footer, SEO, ViewportObserver } from '_organisms'
import {
  ProudCases,
  CookieConsentBar,
  LysAbout,
  LysChallenge,
  LysAdmin,
  LysStudent,
  LysBrands,
  LysResults,
  LysFeedback,
  LysPanel,
} from '_molecules'
import { AppContext } from '_context'
import variables from '_config/css-variables'

import styles from './styles.module.css'

const TESTIMONIALS_SLIDER_OPTIONS = {
  controls: false,
  mouseDrag: true,
  loop: true,
  center: true,
  autoplay: true,
  autoplayHoverPause: true,
  autoplayTimeout: 8000,
  autoplayText: ['', ''],
  navContainer: '#testimonials-nav',
}
const LysTemplate = ({
  proudCases,
  logoLys,
  logoLysAlt,
  firstDescription,
  secondDescription,
  firstWebPhone,
  firstWebPhoneAlt,
  challengeIllustration,
  challengeIllustrationAlt,
  challengeTitle,
  challengeFirstParagraph,
  challengeSecondParagraph,
  challengeThirdParagraph,
  adminTitle,
  adminDescription,
  adminIllustration,
  adminIllustrationAlt,
  panelTitle,
  panelDescription,
  panelIllustration,
  panelIllustrationAlt,
  studentIllustration,
  studentIllustrationAlt,
  studentIllustrationMobile,
  studentIllustrationMobileAlt,
  studentTitle,
  studentDescription,
  brandsTitle,
  brandsDescription,
  brandsIllustration,
  brandsIllustrationAlt,
  resultsTitle,
  resultsFirstLogo,
  resultsFirstLogoAlt,
  resultsSecondLogo,
  resultsSecondLogoAlt,
  resultsTextFirst,
  resultsTextSecond,
  resultsTextThird,
  resultsTextFourth,
  resultsTextFifth,
  resultsTextSixth,
  testimonials,
  pageTitle,
  pageDescription,
  pageBackgroundImage,
  navbarColorOverride,
}) => {
  const { setNavbarDefaults } = useContext(AppContext)
  const { setNavbarColor, setNavbarLight } = useContext(AppContext)
  const [y, setY] = useState(0)
  config({ ssrFadeout: true })
  useScrollPosition(
    ({ currPos }) => {
      setY(currPos.y * -1)
    },
    [],
    null,
    false,
    300
  )

  useEffect(() => {
    return setNavbarDefaults
  }, [setNavbarDefaults])

  return (
    <div>
      <Navbar navbarColorOverride={navbarColorOverride} opacityBg />

      <SEO
        title={pageTitle || SEO_VARIABLES.title}
        description={pageDescription || SEO_VARIABLES.description}
        thumbnail={pageBackgroundImage}
      />

      <section className={styles.display}>
        <LysAbout
          logoLys={logoLys}
          logoLysAlt={logoLysAlt}
          firstDescription={firstDescription}
          secondDescription={secondDescription}
          firstWebPhone={firstWebPhone}
          firstWebPhoneAlt={firstWebPhoneAlt}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['lys-blue-light'])
            setNavbarLight(true)
          }}
        />
      </section>
      <section id="challenge" className={styles.display}>
        <LysChallenge
          challengeIllustration={challengeIllustration}
          challengeIllustrationAlt={challengeIllustrationAlt}
          challengeTitle={challengeTitle}
          challengeFirstParagraph={challengeFirstParagraph}
          challengeSecondParagraph={challengeSecondParagraph}
          challengeThirdParagraph={challengeThirdParagraph}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-white'])
            setNavbarLight(true)
          }}
        />
      </section>
      <section className={styles.display}>
        <LysAdmin
          adminTitle={adminTitle}
          adminDescription={adminDescription}
          adminIllustration={adminIllustration}
          adminIllustrationAlt={adminIllustrationAlt}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['lys-blue-medium'])
            setNavbarLight(false)
          }}
        />
      </section>
      <section className={styles.display}>
        <LysPanel
          panelTitle={panelTitle}
          panelDescription={panelDescription}
          panelIllustration={panelIllustration}
          panelIllustrationAlt={panelIllustrationAlt}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-white'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={styles.display}>
        <LysStudent
          studentIllustration={studentIllustration}
          studentIllustrationAlt={studentIllustrationAlt}
          studentIllustrationMobile={studentIllustrationMobile}
          studentIllustrationMobileAlt={studentIllustrationMobileAlt}
          studentTitle={studentTitle}
          studentDescription={studentDescription}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['lys-green'])
            setNavbarLight(false)
          }}
        />
      </section>
      <section className={classNames(styles.containerBrands, styles.display)}>
        <LysBrands
          brandsTitle={brandsTitle}
          brandsDescription={brandsDescription}
          brandsIllustration={brandsIllustration}
          brandsIllustrationAlt={brandsIllustrationAlt}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['lys-pink'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={classNames(styles.containerFeedback, styles.display)}>
        <LysFeedback
          testimonials={testimonials}
          TESTIMONIALS_SLIDER_OPTIONS={TESTIMONIALS_SLIDER_OPTIONS}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['lys-background'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={classNames(styles.containerResults, styles.display)}>
        <LysResults
          resultsTitle={resultsTitle}
          resultsFirstLogo={resultsFirstLogo}
          resultsFirstLogoAlt={resultsFirstLogoAlt}
          resultsSecondLogo={resultsSecondLogo}
          resultsSecondLogoAlt={resultsSecondLogoAlt}
          resultsTextFirst={resultsTextFirst}
          resultsTextSecond={resultsTextSecond}
          resultsTextThird={resultsTextThird}
          resultsTextFourth={resultsTextFourth}
          resultsTextFifth={resultsTextFifth}
          resultsTextSixth={resultsTextSixth}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['lys-blue-dark'])
            setNavbarLight(false)
          }}
        />
      </section>
      <ProudCases currentProject="Lys" caseStudies={proudCases} extendedCTA />
      <CookieConsentBar />
      <Footer />
    </div>
  )
}

LysTemplate.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  pageDescription: PropTypes.string.isRequired,
  pageBackgroundImage: PropTypes.string.isRequired,
  proudCases: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  logoLys: PropTypes.shape({
    description: PropTypes.string,
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  logoLysAlt: PropTypes.string.isRequired,
  firstDescription: PropTypes.string.isRequired,
  secondDescription: PropTypes.string.isRequired,
  firstWebPhone: PropTypes.shape({
    description: PropTypes.string,
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  firstWebPhoneAlt: PropTypes.string.isRequired,
  challengeIllustration: PropTypes.shape({
    description: PropTypes.string,
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  challengeIllustrationAlt: PropTypes.string.isRequired,
  challengeTitle: PropTypes.string.isRequired,
  challengeFirstParagraph: PropTypes.string.isRequired,
  challengeSecondParagraph: PropTypes.string.isRequired,
  challengeThirdParagraph: PropTypes.string.isRequired,
  adminTitle: PropTypes.string.isRequired,
  adminDescription: PropTypes.string.isRequired,
  adminIllustration: PropTypes.shape({
    description: PropTypes.string,
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  adminIllustrationAlt: PropTypes.string.isRequired,
  panelTitle: PropTypes.string.isRequired,
  panelDescription: PropTypes.string.isRequired,
  panelIllustration: PropTypes.string.isRequired,
  panelIllustrationAlt: PropTypes.string.isRequired,
  studentIllustration: PropTypes.string.isRequired,
  studentIllustrationAlt: PropTypes.string.isRequired,
  studentIllustrationMobile: PropTypes.string.isRequired,
  studentIllustrationMobileAlt: PropTypes.string.isRequired,
  studentTitle: PropTypes.string.isRequired,
  studentDescription: PropTypes.string.isRequired,
  brandsTitle: PropTypes.string.isRequired,
  brandsDescription: PropTypes.string.isRequired,
  brandsIllustration: PropTypes.string.isRequired,
  brandsIllustrationAlt: PropTypes.string.isRequired,
  resultsTitle: PropTypes.string.isRequired,
  resultsFirstLogo: PropTypes.string.isRequired,
  resultsFirstLogoAlt: PropTypes.string.isRequired,
  resultsSecondLogo: PropTypes.string.isRequired,
  resultsSecondLogoAlt: PropTypes.string.isRequired,
  resultsTextFirst: PropTypes.string.isRequired,
  resultsTextSecond: PropTypes.string.isRequired,
  resultsTextThird: PropTypes.string.isRequired,
  resultsTextFourth: PropTypes.string.isRequired,
  resultsTextFifth: PropTypes.string.isRequired,
  resultsTextSixth: PropTypes.string.isRequired,
  testimonials: PropTypes.arrayOf(Object).isRequired,
  navbarColorOverride: PropTypes.string,
}
LysTemplate.defaultProps = {
  navbarColorOverride: undefined,
}
export default LysTemplate
