import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

import { Image, Container, Text, ScrollArrow } from '_atoms'
import ThawAboutBackground from '_images/pages/portfolio/thaw/thaw-about-bg.png'
import ThawLogo from '_images/svgs/thaw-logo.svg'

import styles from './styles.module.css'

const ThawAbout = ({ aboutImage, aboutDescription, aboutBackground }) => {
  return (
    <Container className={styles.aboutContainer}>
      <Image
        src={ThawAboutBackground}
        className={styles.aboutBG}
        alt={aboutBackground.description}
      />
      <div className={styles.aboutGradient}>
        <div className={styles.aboutContent}>
          <ThawLogo className={styles.aboutLogo} />
          <div className={styles.aboutDescriptionWrapper}>
            <Fade delay={200} distance="10%" bottom>
              <Text size="28" splitParagraphs className={styles.aboutDescription}>
                {aboutDescription}
              </Text>
            </Fade>
            <ScrollArrow isButton scrollId="opportunity" className={styles.aboutScrollArrow} />
          </div>
        </div>
        <div className={styles.aboutImage}>
          <Fade delay={450} distance="20%" bottom>
            <Img fluid={aboutImage} imgStyle={{ objectFit: 'cover' }} fadeIn={false} />
          </Fade>
        </div>
      </div>
    </Container>
  )
}
export default ThawAbout

ThawAbout.propTypes = {
  aboutImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  aboutDescription: PropTypes.string.isRequired,
  aboutBackground: PropTypes.shape({
    description: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      sizes: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcSetWebp: PropTypes.string,
      srcWebp: PropTypes.string,
    }),
  }).isRequired,
}
