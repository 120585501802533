import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import config from 'react-reveal/globals'
import classNames from 'classnames'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import { CookieConsentBar, ProudCases, ThawOpportunity, ThawResults } from '_molecules'
import { AppContext } from '_context'
import { Navbar, Footer, ViewportObserver, SEO } from '_organisms'
import variables from '_config/css-variables'
import ThawAbout from '_molecules/portfolio/thaw/about'
import ThawOneApp from '_molecules/portfolio/thaw/one-app'
import ThawFeatures from '_molecules/portfolio/thaw/features'
import ThawSettings from '_molecules/portfolio/thaw/settings'
import ThawForecasts from '_molecules/portfolio/thaw/forecasts'
import ThawShare from '_molecules/portfolio/thaw/share'
import ThawTestimonials from '_molecules/portfolio/thaw/testimonials'
import SEO_VARIABLES from '_config/seo-variables'

import styles from './styles.module.css'

const TESTIMONIALS_SLIDER_OPTIONS = {
  controls: false,
  mouseDrag: true,
  loop: true,
  center: true,
  autoplay: true,
  autoplayHoverPause: true,
  autoplayTimeout: 8000,
  autoplayText: ['', ''],
  navContainer: '#testimonials-nav',
}

const ThawPortfolio = ({
  children,
  pageTitle,
  pageDescription,
  pageBackgroundImage,
  heroImage,
  heroDescription,
  heroBackgroundImage,
  productTitle,
  productDescription,
  productImage,
  challengeTitle,
  challengeDescription,
  challengeDescription2,
  challengeImage,
  featuresDescription,
  featuresImage,
  featuresBackgroundImage,
  personalizedSettingsTitle,
  personalizedSettingsImage,
  personalizedSettingsDescription,
  personalizedSettingsBackgroudImage,
  digitalExperienceTitle,
  digitalExperienceImage,
  digitalExperienceDescription,
  digitalExperienceBackgroundImage,
  shareTitle,
  shareDescription,
  shareImage,
  testimonials,
  resultsDescription1,
  resultsDescription2,
  resultsDescription3,
  proudCases,
  navbarColorOverride,
}) => {
  const { setNavbarDefaults } = useContext(AppContext)
  const { setNavbarColor, setNavbarLight } = useContext(AppContext)
  const [y, setY] = useState(0)
  config({ ssrFadeout: true })

  useScrollPosition(
    ({ currPos }) => {
      setY(currPos.y * -1)
    },
    [],
    null,
    false,
    300
  )
  useEffect(() => {
    return setNavbarDefaults
  }, [setNavbarDefaults])
  return (
    <div className={classNames('app', styles.appThaw)}>
      <Navbar navbarColorOverride={navbarColorOverride} opacityBg />
      <SEO
        title={pageTitle || SEO_VARIABLES.title}
        description={pageDescription || SEO_VARIABLES.description}
        thumbnail={pageBackgroundImage}
      />
      <section className={styles.about}>
        <ThawAbout
          aboutDescription={heroDescription}
          aboutImage={heroImage}
          aboutBackground={heroBackgroundImage}
        />
      </section>
      <div className={styles.orangeBG}>
        <section id="opportunity" className={styles.opportunity}>
          <ThawOpportunity
            title={productTitle}
            description={productDescription}
            image={productImage}
          />
        </section>
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['skyroam-primary'])
            setNavbarLight(false)
          }}
        />
        <section className={styles.oneApp}>
          <ThawOneApp
            title={challengeTitle}
            description={challengeDescription}
            description2={challengeDescription2}
            image={challengeImage}
          />
        </section>
      </div>
      <section>
        <ThawFeatures
          heroBackgroundImage={featuresBackgroundImage}
          featuresDescription={featuresDescription}
          featuresImage={featuresImage}
        />
      </section>

      <section className={styles.settings}>
        <ThawSettings
          title={personalizedSettingsTitle}
          description={personalizedSettingsDescription}
          image={personalizedSettingsImage}
          backgroundImage={personalizedSettingsBackgroudImage}
        />
      </section>

      <section className={styles.forecasts}>
        <ThawForecasts
          title={digitalExperienceTitle}
          description={digitalExperienceDescription}
          image={digitalExperienceImage}
          backgroundImage={digitalExperienceBackgroundImage}
        />
      </section>

      <section className={styles.share}>
        <ThawShare title={shareTitle} description={shareDescription} image={shareImage} />
      </section>

      <section className={styles.testimonials}>
        <ThawTestimonials
          testimonials={testimonials}
          TESTIMONIALS_SLIDER_OPTIONS={TESTIMONIALS_SLIDER_OPTIONS}
        />
      </section>

      <section className={styles.results}>
        <ThawResults
          facial={resultsDescription2}
          info={resultsDescription1}
          seriesC={resultsDescription3}
        />
      </section>
      <div className={styles.portfolio}>
        <ProudCases currentProject="Thaw" caseStudies={proudCases} extendedCTA />
      </div>
      {children}
      <CookieConsentBar />
      <Footer />
    </div>
  )
}

ThawPortfolio.propTypes = {
  children: PropTypes.node,
  pageTitle: PropTypes.string.isRequired,
  pageDescription: PropTypes.string.isRequired,
  navbarColorOverride: PropTypes.string,
  pageBackgroundImage: PropTypes.string.isRequired,
  heroDescription: PropTypes.string.isRequired,
  heroImage: PropTypes.shape({
    description: PropTypes.string,
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  productTitle: PropTypes.string.isRequired,
  productDescription: PropTypes.string.isRequired,
  productImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  heroBackgroundImage: PropTypes.shape({
    description: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      sizes: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcSetWebp: PropTypes.string,
      srcWebp: PropTypes.string,
    }),
  }).isRequired,
  heroBackgroundImageMobile: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  challengeTitle: PropTypes.string.isRequired,
  challengeDescription: PropTypes.string.isRequired,
  challengeDescription2: PropTypes.string.isRequired,
  challengeImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  featuresDescription: PropTypes.string.isRequired,
  featuresImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  featuresBackgroundImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  personalizedSettingsTitle: PropTypes.string.isRequired,
  personalizedSettingsDescription: PropTypes.string.isRequired,
  personalizedSettingsImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  personalizedSettingsBackgroudImage: PropTypes.shape({
    description: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      sizes: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcSetWebp: PropTypes.string,
      srcWebp: PropTypes.string,
    }),
  }).isRequired,
  digitalExperienceTitle: PropTypes.string.isRequired,
  digitalExperienceDescription: PropTypes.string.isRequired,
  digitalExperienceBackgroundImage: PropTypes.shape({
    description: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      sizes: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcSetWebp: PropTypes.string,
      srcWebp: PropTypes.string,
    }),
  }).isRequired,
  digitalExperienceImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  shareTitle: PropTypes.string.isRequired,
  shareDescription: PropTypes.string.isRequired,
  shareImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  shareBackgroundImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  resultsDescription1: PropTypes.PropTypes.shape({
    internal: PropTypes.shape({
      content: PropTypes.string,
    }),
  }).isRequired,
  resultsDescription2: PropTypes.PropTypes.shape({
    internal: PropTypes.shape({
      content: PropTypes.string,
    }),
  }).isRequired,
  resultsDescription3: PropTypes.PropTypes.shape({
    internal: PropTypes.shape({
      content: PropTypes.string,
    }),
  }).isRequired,
  testimonials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  proudCases: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

ThawPortfolio.defaultProps = {
  children: undefined,
  navbarColorOverride: undefined,
}

export default ThawPortfolio
