import React, { useState, useContext, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import classnames from 'classnames'

import {
  ProudCases,
  EchoMobileContainer,
  MeliHeroContainer,
  MeliChallengeContainer,
  MeliDiscoverContainer,
  MeliDiscover2Container,
  MeliCallToActionContainer,
} from '_molecules'
import { SEO, ViewportObserver, EchoDesktopContainer } from '_organisms'
import { AppContext } from '_context'

import styles from './styles.module.css'

const MeliPortifolio = ({
  pageTitle,
  pageDescription,
  thumbnail,
  echoDotMobileImage,
  echoDesktopImage,
  echoDesktopImageAlt,
  heroDescription,
  heroImages,
  challangeTitleFirstPart,
  challangeTitleLastChar,
  challangeDescription,
  challengeImages,
  discoverTitleFirstPart,
  discoverTitleLastChar,
  discoverTitle2FirstPart,
  discoverTitle2LastChar,
  discoverDescription,
  discoverDescription2,
  discoverImages,
  callToActionTitleFirstPart,
  callToActionTitleLastChar,
  callToActionDescription,
  callToActionImages,
  proudCases,
}) => {
  const { setNavbarLight } = useContext(AppContext)
  const [scrolllOffsetY, setScrollOffsetY] = useState(0)
  const [currentSection, setCurrentSection] = useState(0)
  const [lastSectionOffset, setLastSectionOffset] = useState(0)
  const firstRender = useRef(false)

  useEffect(() => {
    const calculateLastSectionOffset = () => {
      const LAST_SECTION_OFFSET =
        document.body.scrollHeight -
        document.getElementById('portfolio')?.clientHeight -
        document.getElementById('footer')?.clientHeight
      setLastSectionOffset(LAST_SECTION_OFFSET)
    }
    calculateLastSectionOffset()
    window.addEventListener('resize', calculateLastSectionOffset)
    return () => window.removeEventListener('resize', calculateLastSectionOffset)
  }, [])

  useScrollPosition(
    ({ currPos }) => {
      setScrollOffsetY(currPos.y * -1)
    },
    [],
    null,
    false,
    300
  )

  return (
    <>
      <SEO
        title={pageTitle || 'Web &amp; Mobile App Design and Development Company'}
        description={pageDescription || 'Web &amp; Mobile App Design and Development Company'}
        thumbnail={thumbnail}
      />

      <EchoMobileContainer
        lastSectionOffset={lastSectionOffset}
        currentSection={currentSection}
        echoDotMobileImage={echoDotMobileImage}
      />
      <EchoDesktopContainer
        currentSection={currentSection}
        callToActionImages={callToActionImages}
        lastSectionOffset={lastSectionOffset}
        echoDesktopImage={echoDesktopImage}
        echoDesktopImageAlt={echoDesktopImageAlt}
        heroImages={heroImages}
        challengeImages={challengeImages}
        discoverImages={discoverImages}
      />

      <main className={styles.hero}>
        <MeliHeroContainer heroDescription={heroDescription} heroImages={heroImages} />
        <ViewportObserver
          scroll={scrolllOffsetY}
          onViewport={() => {
            setNavbarLight(true)
            setCurrentSection(1)
            if (!firstRender.current) {
              setScrollOffsetY(0)
              firstRender.current = true
            }
          }}
        />
      </main>

      <section className={styles.challenge} id="challenge">
        <MeliChallengeContainer
          challangeTitleFirstPart={challangeTitleFirstPart}
          challangeTitleLastChar={challangeTitleLastChar}
          challangeDescription={challangeDescription}
          challengeImages={challengeImages}
        />
        <ViewportObserver
          scroll={scrolllOffsetY}
          onViewport={() => {
            setCurrentSection(2)
            setNavbarLight(false)
          }}
        />
      </section>
      <section className={styles.discover}>
        <MeliDiscoverContainer
          discoverTitleFirstPart={discoverTitleFirstPart}
          discoverTitleLastChar={discoverTitleLastChar}
          discoverDescription={discoverDescription}
          discoverImages={discoverImages}
        />
        <ViewportObserver
          scroll={scrolllOffsetY}
          onViewport={() => {
            setCurrentSection(3)
            setNavbarLight(true)
          }}
        />
      </section>
      <section className={classnames(styles.discover, styles.discover2)}>
        <MeliDiscover2Container
          discoverTitleFirstPart={discoverTitle2FirstPart}
          discoverTitleLastChar={discoverTitle2LastChar}
          discoverDescription={discoverDescription2}
        />
        <ViewportObserver
          scroll={scrolllOffsetY}
          onViewport={() => {
            setCurrentSection(4)
            setNavbarLight(true)
          }}
        />
      </section>
      <section id="callToAction" className={styles.callToAction}>
        <MeliCallToActionContainer
          callToActionTitleLastChar={callToActionTitleLastChar}
          callToActionDescription={callToActionDescription}
          callToActionTitleFirstPart={callToActionTitleFirstPart}
          callToActionImages={callToActionImages}
        />
        <ViewportObserver
          scroll={scrolllOffsetY}
          onViewport={() => {
            setCurrentSection(5)
            setNavbarLight(false)
          }}
        />
      </section>

      {proudCases && (
        <div id="portfolio" className={styles.portfolio}>
          <ProudCases currentProject="Meli" caseStudies={proudCases} extendedCTA />
          <ViewportObserver
            scroll={scrolllOffsetY}
            onViewport={() => {
              setNavbarLight(true)
            }}
          />
        </div>
      )}
    </>
  )
}
MeliPortifolio.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  pageDescription: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  echoDesktopImage: PropTypes.string.isRequired,
  echoDesktopImageAlt: PropTypes.string.isRequired,
  heroDescription: PropTypes.shape({}).isRequired,
  echoDotMobileImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  heroImages: PropTypes.shape({
    phone: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    logo: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    woman: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    cart: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
  }).isRequired,
  challengeImages: PropTypes.shape({
    backBallon: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    frontBallon: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
  }).isRequired,
  discoverImages: PropTypes.shape({
    dialog1: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog2: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog3: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog4: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog5: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog6: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog7: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
  }).isRequired,
  discoverTitleFirstPart: PropTypes.string.isRequired,
  discoverTitle2LastChar: PropTypes.string.isRequired,
  challangeTitleFirstPart: PropTypes.string.isRequired,
  challangeTitleLastChar: PropTypes.string.isRequired,
  challangeDescription: PropTypes.shape({}).isRequired,
  discoverTitleLastChar: PropTypes.string.isRequired,
  discoverTitle2FirstPart: PropTypes.string.isRequired,
  discoverDescription: PropTypes.shape({}).isRequired,
  discoverDescription2: PropTypes.shape({}).isRequired,
  callToActionTitleFirstPart: PropTypes.string.isRequired,
  callToActionTitleLastChar: PropTypes.string.isRequired,
  callToActionDescription: PropTypes.shape({}).isRequired,
  callToActionImages: PropTypes.shape({
    controller: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    phone: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
  }).isRequired,
  proudCases: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default MeliPortifolio
