import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import Img from 'gatsby-image'

import { Image, Container, Text, SliderNav, Heading } from '_atoms'
import { TinySlider } from '_molecules'
import { useWindowSize } from '_hooks'

import style from './styles.module.css'

const BluebenxNoCurbs = ({
  noCurbsTitle,
  noCurbsDescription,
  noCurbsMobileScreens,
  NOCURBS_SLIDER_OPTIONS,
}) => {
  const [width] = useWindowSize()
  const maxWidth = 992
  const isMobile = width < maxWidth

  return (
    <Container className={style.noCurbsContainer}>
      <div className={style.noCurbsBg}>
        <div className={style.noCurbsContent}>
          <Fade distance="10%" bottom>
            <Heading type="h2" className={style.noCurbsTitle}>
              {noCurbsTitle}
              <span className={style.dotSecondary}>.</span>
            </Heading>
          </Fade>
          <div className={style.noCurbsDescriptionWrapper}>
            <Fade delay={200} distance="10%" bottom>
              <Text size="22" splitParagraphs className={style.noCurbsDescription}>
                {noCurbsDescription}
              </Text>
            </Fade>
          </div>
        </div>
        <div className={style.noCurbsImageGroup}>
          {!isMobile ? (
            <Fade delay={450} distance="20%" bottom>
              {noCurbsMobileScreens.map(el => (
                <Img
                  fluid={el.image}
                  className={style.noCurbsImg}
                  alt={el.alt}
                  objectFit="contain"
                  imgStyle={{ objectFit: 'contain' }}
                />
              ))}
            </Fade>
          ) : (
            <div className={style.sliderContainer}>
              <Fade distance="10%" bottom>
                <TinySlider
                  id="mobileScreens"
                  options={NOCURBS_SLIDER_OPTIONS}
                  className={style.sliderTest}
                >
                  {noCurbsMobileScreens.map(el => (
                    <div key={`mobileScreens-${el.id}`} className={style.sliderImageContainer}>
                      <Image src={el.image.src} className={style.sliderImage} alt={el.alt} />
                    </div>
                  ))}
                </TinySlider>
                <div id="mobileScreens-nav" className={style.sliderIcons}>
                  {noCurbsMobileScreens.map(({ id }) => (
                    <SliderNav key={id} className={style.sliderNav} />
                  ))}
                </div>
              </Fade>
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}

BluebenxNoCurbs.propTypes = {
  noCurbsTitle: PropTypes.string.isRequired,
  noCurbsDescription: PropTypes.string.isRequired,
  noCurbsMobileScreens: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.shape({
        aspectRatio: PropTypes.number,
        sizes: PropTypes.string,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        srcSetWebp: PropTypes.string,
        srcWebp: PropTypes.string,
      }).isRequired,
      alt: PropTypes.string.isRequired,
    })
  ).isRequired,
  NOCURBS_SLIDER_OPTIONS: PropTypes.PropTypes.shape({
    autoplay: PropTypes.bool,
    autoplayHoverPause: PropTypes.bool,
    autoplayText: PropTypes.arrayOf(PropTypes.string),
    autoplayTimeout: PropTypes.number,
    center: PropTypes.bool,
    controls: PropTypes.bool,
    loop: PropTypes.bool,
    mouseDrag: PropTypes.bool,
    nav: PropTypes.bool,
    navPosition: PropTypes.string,
    navContainer: PropTypes.string,
    arrowKeys: PropTypes.bool,
    gutter: PropTypes.number,
    autoWidth: PropTypes.bool,
  }).isRequired,
}

export default BluebenxNoCurbs
