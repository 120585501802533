import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

import ThawForecastsBackground from '_images/pages/portfolio/thaw/thaw-forecasts-bg.png'
import { Container, Text, Image } from '_atoms'

import styles from './styles.module.css'

const ThawForecasts = ({ title, description, image, backgroundImage }) => {
  return (
    <Container className={styles.forecastsContainer}>
      <Image
        src={ThawForecastsBackground}
        className={styles.forecastsBG}
        alt={backgroundImage.description}
      />
      <div className={styles.forecastsBGMask}>
        <div className={styles.forecastsContentWrapper}>
          <div className={styles.forecastsImage}>
            <Fade delay={450} distance="20%" bottom>
              <Img
                fluid={image}
                imgStyle={{
                  objectFit: 'cover',
                }}
                fadeIn={false}
              />
            </Fade>
          </div>
          <div className={styles.forecastsContent}>
            <Fade distance="10%" bottom>
              <Text size="48" bold className={styles.forecastsTitle}>
                {title}
                <span className={styles.dotSecondary}>.</span>
              </Text>
            </Fade>
            <div className={styles.forecastsDescriptionWrapper}>
              <Fade delay={200} distance="10%" bottom>
                <Text size="22" splitParagraphs className={styles.forecastsDescription}>
                  {description}
                </Text>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

ThawForecasts.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.shape({
    description: PropTypes.string,
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  backgroundImage: PropTypes.shape({
    description: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      sizes: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcSetWebp: PropTypes.string,
      srcWebp: PropTypes.string,
    }),
  }).isRequired,
}

export default ThawForecasts
