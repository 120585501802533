import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import Img from 'gatsby-image'

import { Image, Text } from '_atoms'
import ThawFeaturesBackground from '_images/pages/portfolio/thaw/thaw-features-bg.png'

import styles from './styles.module.css'

const ThawFeatures = ({ featuresImage, featuresDescription }) => {
  return (
    <div className={styles.featuresGradient}>
      <Image src={ThawFeaturesBackground} className={styles.featuresBg} alt="backgroud" />
      <div className={styles.featuresContentWrapper}>
        <div className={styles.featuresImage}>
          <Fade delay={350} distance="20%" bottom>
            <Img fluid={featuresImage} imgStyle={{ objectFit: 'contain' }} fadeIn={false} />
          </Fade>
        </div>
        <div className={styles.featuresDescriptionWrapper}>
          <Fade delay={200} distance="10%" bottom>
            <Text size="28" splitParagraphs className={styles.featuresDescription}>
              {featuresDescription}
            </Text>
          </Fade>
        </div>
      </div>
    </div>
  )
}
export default ThawFeatures

ThawFeatures.propTypes = {
  featuresImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  featuresDescription: PropTypes.string.isRequired,
}
