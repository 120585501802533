import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import config from 'react-reveal/globals'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import { AppContext } from '_context'
import variables from '_config/css-variables'
import SEO_VARIABLES from '_config/seo-variables'
import { Image } from '_atoms'
import {
  CookieConsentBar,
  ProudCases,
  BluebenxHero,
  BluebenxVision,
  BluebenxMiddle,
  BluebenxWallet,
  BluebenxTokens,
  BluebenxResults,
} from '_molecules'
import { Navbar, Footer, ViewportObserver, SEO } from '_organisms'

import BluebenxNoCurbs from '../../../molecules/portfolio/bluebenx/no-curbs'

import styles from './styles.module.css'

const NOCURBS_SLIDER_OPTIONS = {
  controls: false,
  mouseDrag: true,
  loop: true,
  center: true,
  autoplay: true,
  autoplayHoverPause: true,
  autoplayTimeout: 8000,
  autoplayText: ['', ''],
  nav: true,
  navPosition: 'bottom',
  navContainer: '#mobileScreens-nav',
  arrowKeys: true,
  items: 3,
  gutter: 20,
  autoWidth: true,
}

const BluebenxPortfolio = ({
  children,
  navbarColorOverride,
  pageTitle,
  pageDescription,
  pageBackgroundImage,
  bluebenxLogo,
  bluebenxLogoAlt,
  heroTitle,
  heroDescription,
  heroMobileScreen,
  heroMobileScreenAlt,
  heroBackground,
  visionTitle,
  visionDescription,
  visionBackground,
  visionImage,
  visionImageAlt,
  middleText,
  middleBackground,
  walletTitle,
  walletDescription,
  walletMobileScreen,
  walletMobileScreenAlt,
  noCurbsTitle,
  noCurbsDescription,
  noCurbsMobileScreens,
  noCurbsBackground,
  noCurbsBackgroundAlt,
  tokensTitle,
  tokensDescription,
  stellarBackground,
  stellarBackgroundAlt,
  resultsImage,
  resultsImageAlt,
  resultsTitle,
  resultsText1A,
  resultsText1B,
  resultsText1C,
  resultsText2A,
  resultsText2B,
  resultsText2C,
  proudCases,
}) => {
  const { setNavbarDefaults } = useContext(AppContext)
  const { setNavbarColor, setNavbarLight } = useContext(AppContext)
  const [y, setY] = useState(0)
  config({ ssrFadeout: true })

  useScrollPosition(
    ({ currPos }) => {
      setY(currPos.y * -1)
    },
    [],
    null,
    false,
    300
  )
  useEffect(() => {
    return setNavbarDefaults
  }, [setNavbarDefaults])

  return (
    <div className={classNames('app', styles.appBluebenx)}>
      <Navbar navbarColorOverride={navbarColorOverride} opacityBg />
      <SEO
        title={pageTitle || SEO_VARIABLES.title}
        description={pageDescription || SEO_VARIABLES.description}
        thumbnail={pageBackgroundImage}
      />
      <section className={styles.hero}>
        <BluebenxHero
          bluebenxLogo={bluebenxLogo}
          bluebenxLogoAlt={bluebenxLogoAlt}
          heroTitle={heroTitle}
          heroDescription={heroDescription}
          heroMobileScreen={heroMobileScreen}
          heroMobileScreenAlt={heroMobileScreenAlt}
          heroBackground={heroBackground}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['BB-blue-dark'])
            setNavbarLight(false)
          }}
        />
      </section>
      <section className={styles.vision} id="vision">
        <BluebenxVision
          visionTitle={visionTitle}
          visionDescription={visionDescription}
          visionImage={visionImage}
          visionImageAlt={visionImageAlt}
          visionBackground={visionBackground}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['BB-blue'])
            setNavbarLight(false)
          }}
        />
      </section>
      <section className={styles.middle}>
        <BluebenxMiddle middleText={middleText} middleBackground={middleBackground} />
      </section>
      <section className={styles.wallet}>
        <BluebenxWallet
          walletTitle={walletTitle}
          walletDescription={walletDescription}
          walletMobileScreen={walletMobileScreen}
          walletMobileScreenAlt={walletMobileScreenAlt}
        />
      </section>
      <Image src={noCurbsBackground} className={styles.noCurbsImage} alt={noCurbsBackgroundAlt} />
      <section className={styles.noCurbs}>
        <BluebenxNoCurbs
          noCurbsTitle={noCurbsTitle}
          noCurbsDescription={noCurbsDescription}
          noCurbsMobileScreens={noCurbsMobileScreens}
          NOCURBS_SLIDER_OPTIONS={NOCURBS_SLIDER_OPTIONS}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['BB-blue-light'])
            setNavbarLight(false)
          }}
        />
      </section>
      <section className={styles.tokens}>
        <BluebenxTokens
          tokensTitle={tokensTitle}
          tokensDescription={tokensDescription}
          stellarBackground={stellarBackground}
          resultsImage={resultsImage}
          resultsImageAlt={resultsImageAlt}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['BB-blue-dark'])
            setNavbarLight(false)
          }}
        />
      </section>
      <Image
        src={stellarBackground}
        className={styles.stellarBackground}
        alt={stellarBackgroundAlt}
      />
      <Image src={resultsImage} className={styles.resultsImage} alt={resultsImageAlt} />
      <section className={styles.results}>
        <BluebenxResults
          resultsTitle={resultsTitle}
          resultsText1A={resultsText1A}
          resultsText1B={resultsText1B}
          resultsText1C={resultsText1C}
          resultsText2A={resultsText2A}
          resultsText2B={resultsText2B}
          resultsText2C={resultsText2C}
        />
      </section>
      <div className={styles.portfolio}>
        <ProudCases currentProject="Bluebenx" caseStudies={proudCases} extendedCTA />
      </div>
      {children}
      <CookieConsentBar />
      <Footer />
    </div>
  )
}

BluebenxPortfolio.propTypes = {
  children: PropTypes.node,
  navbarColorOverride: PropTypes.string,
  pageTitle: PropTypes.string.isRequired,
  pageDescription: PropTypes.string.isRequired,
  pageBackgroundImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  bluebenxLogo: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  bluebenxLogoAlt: PropTypes.string.isRequired,
  heroTitle: PropTypes.string.isRequired,
  heroDescription: PropTypes.string.isRequired,
  heroMobileScreen: PropTypes.string.isRequired,
  heroMobileScreenAlt: PropTypes.string.isRequired,
  heroBackground: PropTypes.string.isRequired,
  visionTitle: PropTypes.string.isRequired,
  visionDescription: PropTypes.string.isRequired,
  visionImage: PropTypes.string.isRequired,
  visionImageAlt: PropTypes.string.isRequired,
  visionBackground: PropTypes.string.isRequired,
  middleText: PropTypes.string.isRequired,
  middleBackground: PropTypes.string.isRequired,
  walletTitle: PropTypes.string.isRequired,
  walletDescription: PropTypes.string.isRequired,
  walletMobileScreen: PropTypes.string.isRequired,
  walletMobileScreenAlt: PropTypes.string.isRequired,
  noCurbsTitle: PropTypes.string.isRequired,
  noCurbsDescription: PropTypes.string.isRequired,
  noCurbsMobileScreens: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.shape({
        aspectRatio: PropTypes.number,
        sizes: PropTypes.string,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        srcSetWebp: PropTypes.string,
        srcWebp: PropTypes.string,
      }).isRequired,
      alt: PropTypes.string.isRequired,
    })
  ).isRequired,
  noCurbsBackground: PropTypes.string.isRequired,
  noCurbsBackgroundAlt: PropTypes.string.isRequired,
  tokensTitle: PropTypes.string.isRequired,
  tokensDescription: PropTypes.string.isRequired,
  stellarBackground: PropTypes.string.isRequired,
  stellarBackgroundAlt: PropTypes.string.isRequired,
  resultsImage: PropTypes.string.isRequired,
  resultsImageAlt: PropTypes.string.isRequired,
  resultsTitle: PropTypes.string.isRequired,
  resultsText1A: PropTypes.string.isRequired,
  resultsText1B: PropTypes.string.isRequired,
  resultsText1C: PropTypes.string.isRequired,
  resultsText2A: PropTypes.string.isRequired,
  resultsText2B: PropTypes.string.isRequired,
  resultsText2C: PropTypes.string.isRequired,
  proudCases: PropTypes.arrayOf(Object).isRequired,
}

BluebenxPortfolio.defaultProps = {
  children: undefined,
  navbarColorOverride: undefined,
}

export default BluebenxPortfolio
