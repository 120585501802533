import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import config from 'react-reveal/globals'
import classNames from 'classnames'

import { AppContext } from '_context'
import { CookieConsentBar } from '_molecules'
import { Navbar, Footer } from '_organisms'

import '_styles/base.css'

const Layout = ({ children, className, navbarColorOverride }) => {
  const { setNavbarDefaults } = useContext(AppContext)
  config({ ssrFadeout: true })

  useEffect(() => {
    return setNavbarDefaults
  }, [setNavbarDefaults])

  return (
    <div className={classNames('app', className)}>
      <Navbar navbarColorOverride={navbarColorOverride} />
      {children}
      <CookieConsentBar />
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  navbarColorOverride: PropTypes.string,
}

Layout.defaultProps = {
  className: undefined,
  navbarColorOverride: undefined,
}

export default Layout
