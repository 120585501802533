import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'

import { AppContext } from '_context'
import { Navbar } from '_organisms'

import '_styles/base.css'

const NavbarOnly = ({ children, cta }) => {
  const { setNavbarDefaults } = useContext(AppContext)

  useEffect(() => {
    return setNavbarDefaults
  }, [setNavbarDefaults])

  return (
    <div className="app">
      <Navbar cta={cta} />
      {children}
    </div>
  )
}

NavbarOnly.propTypes = {
  children: PropTypes.node.isRequired,
  cta: PropTypes.shape({
    text: PropTypes.string,
    scrollTo: PropTypes.string,
    id: PropTypes.string,
  }),
}

NavbarOnly.defaultProps = {
  cta: undefined,
}

export default NavbarOnly
