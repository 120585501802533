import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import config from 'react-reveal/globals'
import classNames from 'classnames'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import {
  CookieConsentBar,
  ProudCases,
  SwfastContainer,
  SwfastImage,
  BackgroundStripes,
  SwfastChallengeContainer,
  SwfastSolutionContainer,
  DescriptionImage,
  SwfastResults,
} from '_molecules'
import { AppContext } from '_context'
import {
  Navbar,
  Footer,
  ViewportObserver,
  SEO,
  Testimonials,
  SwfastWhiteLabelContainer,
} from '_organisms'
import variables from '_config/css-variables'

import styles from './styles.module.css'

const SwfastPortfolio = ({
  children,
  className,
  navbarColorOverride,
  pageTitle,
  pageDescription,
  pageThumbnailUrl,
  heroDescription,
  heroImage,
  heroImageAlt,
  challengeTitle,
  challengeDescription,
  solutionTitle,
  solutionDescription,
  solutionImage,
  solutionImageAlt,
  whiteLabelTitle,
  whiteLabelDescription,
  whiteLabelImages,
  deliveryTitle,
  deliveryDescription,
  deliveryImage,
  deliveryImageAlt,
  takeoutTitle,
  takeoutDescription,
  takeoutImage,
  takeoutImageAlt,
  scanTitle,
  scanDescription,
  scanImage,
  scanImageAlt,
  testimonials,
  resultsFirstDescription,
  resultsStoreDescriptionFirstPart,
  resultsStoreDescriptionSecondPart,
  resultsStoreDescriptionThirdPart,
  resultsDeadlineDescriptionFirstPart,
  resultsDeadlineDescriptionSecondPart,
  storeImage,
  deadlineAndBudgetImage,
  proudCases,
  challengeList,
}) => {
  const { setNavbarDefaults } = useContext(AppContext)
  const { setNavbarColor, setNavbarLight } = useContext(AppContext)
  const [y, setY] = useState(0)
  config({ ssrFadeout: true })
  useScrollPosition(
    ({ currPos }) => {
      setY(currPos.y * -1)
    },
    [],
    null,
    false,
    300
  )

  useEffect(() => {
    return setNavbarDefaults
  }, [setNavbarDefaults])

  return (
    <div className={classNames('app', className)}>
      <Navbar navbarColorOverride={navbarColorOverride} opacityBg />
      <SEO
        title={pageTitle || 'Web &amp; Mobile App Design and Development Company'}
        description={pageDescription || 'Web &amp; Mobile App Design and Development Company'}
        thumbnail={pageThumbnailUrl}
      />
      <main className={styles.hero}>
        <SwfastContainer content={heroDescription} />
        <SwfastImage fluid={heroImage} alt={heroImageAlt} />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['swfast-primary'])
            setNavbarLight(false)
          }}
        />
      </main>

      <section id="challenge" className={styles.challenge}>
        <BackgroundStripes />
        <SwfastChallengeContainer
          title={challengeTitle}
          description={challengeDescription}
          challengeList={challengeList}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['swfast-green-light'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.solution}>
        <SwfastSolutionContainer
          title={solutionTitle}
          description={solutionDescription}
          image={solutionImage}
          imageAlt={solutionImageAlt}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-gray-lightest'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={styles.whiteLabel}>
        <BackgroundStripes />
        <SwfastWhiteLabelContainer
          title={whiteLabelTitle}
          description={whiteLabelDescription}
          images={whiteLabelImages}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['swfast-green-light'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.products}>
        <DescriptionImage
          title={deliveryTitle}
          description={deliveryDescription}
          image={deliveryImage}
          imageDescription={deliveryImageAlt}
          imageSize="35"
          imageSizeMobile="80"
          backgroundColor={variables['color-gray-lightest']}
        />

        <DescriptionImage
          title={takeoutTitle}
          description={takeoutDescription}
          image={takeoutImage}
          imageDescription={takeoutImageAlt}
          imageSize="45"
          imageSizeMobile="100"
          reverse
          backgroundColor={variables['color-gray-lightest']}
        />
        <DescriptionImage
          title={scanTitle}
          description={scanDescription}
          image={scanImage}
          imageDescription={scanImageAlt}
          imageSize="25"
          imageSizeMobile="80"
          backgroundColor={variables['color-gray-lightest']}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-gray-lightest'])
            setNavbarLight(true)
          }}
        />
      </section>
      <section className={styles.testimonials}>
        <Testimonials testimonials={testimonials} />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-white'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={styles.results}>
        <SwfastResults
          resultsFirstDescription={resultsFirstDescription}
          resultsStoreDescriptionFirstPart={resultsStoreDescriptionFirstPart}
          resultsStoreDescriptionSecondPart={resultsStoreDescriptionSecondPart}
          resultsStoreDescriptionThirdPart={resultsStoreDescriptionThirdPart}
          resultsDeadlineDescriptionFirstPart={resultsDeadlineDescriptionFirstPart}
          resultsDeadlineDescriptionSecondPart={resultsDeadlineDescriptionSecondPart}
          storeImage={storeImage}
          deadlineAndBudgetImage={deadlineAndBudgetImage}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-dark'])
            setNavbarLight(false)
          }}
        />
      </section>

      <div className={styles.portfolio}>
        <ProudCases currentProject="Swfast" caseStudies={proudCases} extendedCTA />

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-white'])
            setNavbarLight(true)
          }}
        />
      </div>
      {children}
      <CookieConsentBar />
      <Footer />
    </div>
  )
}

SwfastPortfolio.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  navbarColorOverride: PropTypes.string,
  pageTitle: PropTypes.string.isRequired,
  pageDescription: PropTypes.string.isRequired,
  pageThumbnailUrl: PropTypes.string.isRequired,
  heroDescription: PropTypes.string.isRequired,
  heroImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  heroImageAlt: PropTypes.string.isRequired,
  challengeTitle: PropTypes.string.isRequired,
  challengeDescription: PropTypes.string.isRequired,
  solutionTitle: PropTypes.string.isRequired,
  solutionDescription: PropTypes.string.isRequired,
  solutionImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  solutionImageAlt: PropTypes.string.isRequired,
  whiteLabelTitle: PropTypes.string.isRequired,
  whiteLabelDescription: PropTypes.string.isRequired,
  whiteLabelImages: PropTypes.arrayOf(PropTypes.object).isRequired,
  deliveryTitle: PropTypes.string.isRequired,
  deliveryDescription: PropTypes.string.isRequired,
  deliveryImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  storeImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  deadlineAndBudgetImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  deliveryImageAlt: PropTypes.string.isRequired,
  takeoutTitle: PropTypes.string.isRequired,
  takeoutDescription: PropTypes.string.isRequired,
  takeoutImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  takeoutImageAlt: PropTypes.string.isRequired,
  scanTitle: PropTypes.string.isRequired,
  scanDescription: PropTypes.string.isRequired,
  scanImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  scanImageAlt: PropTypes.string.isRequired,
  testimonials: PropTypes.arrayOf(PropTypes.object).isRequired,
  resultsFirstDescription: PropTypes.string,
  proudCases: PropTypes.arrayOf(PropTypes.object).isRequired,
  resultsStoreDescriptionFirstPart: PropTypes.string.isRequired,
  resultsStoreDescriptionSecondPart: PropTypes.string.isRequired,
  resultsStoreDescriptionThirdPart: PropTypes.string.isRequired,
  resultsDeadlineDescriptionFirstPart: PropTypes.string.isRequired,
  resultsDeadlineDescriptionSecondPart: PropTypes.string.isRequired,
  challengeList: PropTypes.arrayOf(PropTypes.string).isRequired,
}

SwfastPortfolio.defaultProps = {
  className: undefined,
  navbarColorOverride: undefined,
  children: undefined,
  resultsFirstDescription: undefined,
}

export default SwfastPortfolio
