import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import config from 'react-reveal/globals'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import classNames from 'classnames'

import SEO_VARIABLES from '_config/seo-variables'
import {
  ProudCases,
  CookieConsentBar,
  MoveHero,
  SectionChallenge,
  InfoSection,
  MissionSection,
  DifferentialSection,
  ReportingSection,
  MoveResults,
} from '_molecules'
import { Navbar, Footer, SEO, ViewportObserver } from '_organisms'
import { AppContext } from '_context'
import variables from '_config/css-variables'

import styles from './styles.module.css'

const MovePortfolio = ({
  navbarColorOverride,
  pageTitle,
  pageDescription,
  logo,
  logoDescription,
  heroDescription,
  heroVectary,
  heroVectaryDescription,
  challengeTitle,
  challengeDescription,
  backgroundChallenge,
  iconDrivers,
  altIconDrivers,
  titleDrivers,
  descriptionDrivers,
  iconCharging,
  altIconCharging,
  titleCharging,
  descriptionCharging,
  missionBackground,
  missionIcon,
  missionIconAlt,
  missionTitle,
  missionText,
  differentialBackground,
  differentialTitle,
  differentialDescription,
  differentialCardCode,
  differentialCardCodeAlt,
  reportingTitle,
  reportingDescription,
  reportingImageDashboard,
  reportingImageDashboardAlt,
  reportingMobile,
  reportingMobileAlt,
  proudCases,
  iconAES,
  iconAESAlt,
  resultsTitle,
  resultTitle1,
  resultDescription1A,
  resultDescription1B,
  resultDescription1C,
  iconExperience,
  iconExperienceAlt,
  resultTitle2,
  resultDescription2A,
  resultDescription2B,
  heroBackgroundTexture,
}) => {
  const { setNavbarDefaults } = useContext(AppContext)
  const { setNavbarColor, setNavbarLight } = useContext(AppContext)
  const [y, setY] = useState(0)
  config({ ssrFadeout: true })
  useScrollPosition(
    ({ currPos }) => {
      setY(currPos.y * -1)
    },
    [],
    null,
    false,
    300
  )

  useEffect(() => {
    return setNavbarDefaults
  }, [setNavbarDefaults])
  return (
    <div className={classNames('app', styles.appMove)}>
      <Navbar navbarColorOverride={navbarColorOverride} opacityBg />
      <SEO
        title={pageTitle || SEO_VARIABLES.title}
        description={pageDescription || SEO_VARIABLES.description}
      />
      <section className={styles.hero}>
        <MoveHero
          logo={logo}
          logoDescription={logoDescription}
          heroDescription={heroDescription}
          heroVectary={heroVectary}
          heroBackgroundTexture={heroBackgroundTexture}
          heroVectaryDescription={heroVectaryDescription}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['move-primary'])
            setNavbarLight(false)
          }}
        />
      </section>
      <section
        className={styles.sectionChallenge}
        style={{ backgroundImage: `url(${backgroundChallenge})` }}
        id="challenge"
      >
        <div className={styles.sectionChallengeLinear}>
          <SectionChallenge
            challengeTitle={challengeTitle}
            challengeDescription={challengeDescription}
          />
          <ViewportObserver
            scroll={y}
            onViewport={() => {
              setNavbarColor(variables['color-white'])
              setNavbarLight(true)
            }}
          />
        </div>
      </section>
      <section>
        <InfoSection
          iconDrivers={iconDrivers}
          altIconDrivers={altIconDrivers}
          titleDrivers={titleDrivers}
          descriptionDrivers={descriptionDrivers}
          iconCharging={iconCharging}
          altIconCharging={altIconCharging}
          titleCharging={titleCharging}
          descriptionCharging={descriptionCharging}
        />
      </section>
      <section
        className={styles.sectionMission}
        style={{ backgroundImage: `url(${missionBackground})` }}
      >
        <div className={styles.sectionMissionLinear}>
          <MissionSection
            missionIcon={missionIcon}
            missionIconAlt={missionIconAlt}
            missionTitle={missionTitle}
            missionText={missionText}
          />
        </div>
      </section>
      <section
        style={{ backgroundImage: `url(${differentialBackground})` }}
        className={styles.differentialSection}
      >
        <DifferentialSection
          differentialBackground={differentialBackground}
          differentialTitle={differentialTitle}
          differentialDescription={differentialDescription}
          differentialCardCode={differentialCardCode}
          differentialCardCodeAlt={differentialCardCodeAlt}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-white'])
            setNavbarLight(true)
          }}
        />
      </section>
      <section className={styles.reportingSection}>
        <ReportingSection
          reportingTitle={reportingTitle}
          reportingDescription={reportingDescription}
          reportingImageDashboard={reportingImageDashboard}
          reportingImageDashboardAlt={reportingImageDashboardAlt}
          reportingMobile={reportingMobile}
          reportingMobileAlt={reportingMobileAlt}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['move-primary'])
            setNavbarLight(false)
          }}
        />
      </section>
      <section className={styles.results}>
        <MoveResults
          iconAES={iconAES}
          iconAESAlt={iconAESAlt}
          resultsTitle={resultsTitle}
          resultTitle1={resultTitle1}
          resultDescription1A={resultDescription1A}
          resultDescription1B={resultDescription1B}
          resultDescription1C={resultDescription1C}
          iconExperience={iconExperience}
          iconExperienceAlt={iconExperienceAlt}
          resultTitle2={resultTitle2}
          resultDescription2A={resultDescription2A}
          resultDescription2B={resultDescription2B}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['move-primary'])
            setNavbarLight(false)
          }}
        />
      </section>
      <div className={styles.proudCases}>
        <ProudCases currentProject="Move" caseStudies={proudCases} extendedCTA />
      </div>
      <CookieConsentBar />
      <Footer />
    </div>
  )
}

MovePortfolio.propTypes = {
  navbarColorOverride: PropTypes.string,
  pageTitle: PropTypes.string.isRequired,
  pageDescription: PropTypes.string.isRequired,
  heroBackgroundTexture: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  logoDescription: PropTypes.string.isRequired,
  heroDescription: PropTypes.string.isRequired,
  heroVectary: PropTypes.string.isRequired,
  heroVectaryDescription: PropTypes.string.isRequired,
  challengeTitle: PropTypes.string.isRequired,
  backgroundChallenge: PropTypes.string.isRequired,
  challengeDescription: PropTypes.string.isRequired,
  iconDrivers: PropTypes.string.isRequired,
  altIconDrivers: PropTypes.string.isRequired,
  titleDrivers: PropTypes.string.isRequired,
  descriptionDrivers: PropTypes.shape({}).isRequired,
  iconCharging: PropTypes.string.isRequired,
  altIconCharging: PropTypes.string.isRequired,
  titleCharging: PropTypes.string.isRequired,
  descriptionCharging: PropTypes.shape({}).isRequired,
  missionBackground: PropTypes.string.isRequired,
  missionIcon: PropTypes.string.isRequired,
  missionIconAlt: PropTypes.string.isRequired,
  missionTitle: PropTypes.string.isRequired,
  missionText: PropTypes.string.isRequired,
  differentialBackground: PropTypes.string.isRequired,
  differentialTitle: PropTypes.string.isRequired,
  differentialDescription: PropTypes.string.isRequired,
  differentialCardCode: PropTypes.string.isRequired,
  differentialCardCodeAlt: PropTypes.string.isRequired,
  reportingTitle: PropTypes.string.isRequired,
  reportingDescription: PropTypes.string.isRequired,
  reportingImageDashboard: PropTypes.string.isRequired,
  reportingImageDashboardAlt: PropTypes.string.isRequired,
  reportingMobile: PropTypes.string.isRequired,
  reportingMobileAlt: PropTypes.string.isRequired,
  iconAES: PropTypes.string.isRequired,
  iconAESAlt: PropTypes.string.isRequired,
  resultsTitle: PropTypes.string.isRequired,
  resultTitle1: PropTypes.string.isRequired,
  resultDescription1A: PropTypes.string.isRequired,
  resultDescription1B: PropTypes.string.isRequired,
  resultDescription1C: PropTypes.string.isRequired,
  iconExperience: PropTypes.string.isRequired,
  iconExperienceAlt: PropTypes.string.isRequired,
  resultTitle2: PropTypes.string.isRequired,
  resultDescription2A: PropTypes.string.isRequired,
  resultDescription2B: PropTypes.string.isRequired,
  proudCases: PropTypes.arrayOf(Object).isRequired,
}
MovePortfolio.defaultProps = {
  navbarColorOverride: undefined,
}

export default MovePortfolio
