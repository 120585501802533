import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

import { Container, Text } from '_atoms'

import styles from './styles.module.css'

const ThawOneApp = ({ title, description, description2, image }) => {
  return (
    <Container className={styles.oneAppContainer}>
      <div className={styles.oneAppBg}>
        <div className={styles.oneAppContent}>
          <Fade distance="10%" bottom>
            <Text size="48" bold className={styles.oneAppTitle}>
              {title}
              <span className={styles.dotSecondary}>.</span>
            </Text>
          </Fade>

          <div className={styles.oneAppDescriptionWrapper}>
            <Fade delay={200} distance="10%" bottom>
              <Text size="22" splitParagraphs className={styles.oneAppDescription}>
                {description}
              </Text>
              <i>
                <Text size="22" splitParagraphs className={styles.oneAppDescription}>
                  {description2}
                </Text>
              </i>
            </Fade>
          </div>
        </div>

        <div className={styles.oneAppImage}>
          <Fade delay={450} distance="20%" bottom>
            <Img
              fluid={image}
              imgStyle={{
                objectFit: 'contain',
                position: 'relative',
              }}
              fadeIn={false}
            />
          </Fade>
        </div>
      </div>
    </Container>
  )
}

ThawOneApp.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  description2: PropTypes.string.isRequired,
  image: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
}

export default ThawOneApp
