import React from 'react'
import Fade from 'react-reveal/Fade'
import PropTypes from 'prop-types'

import { Button } from '_atoms'
import { StripedQuote } from '_organisms'

import styles from './styles.module.css'

const QuoteSection = ({ quote, buttonText, onClick, id }) => (
  <section className={styles.quoteSection}>
    <StripedQuote
      quotes={quote}
      className={styles.quoteSectionStripedQuoteBg}
      containerClass={styles.quoteSectionStripedQuoteContainer}
    />

    <Fade distance="20%" bottom>
      <Button type="primary" className={styles.quoteSectionButton} onClick={onClick} id={id}>
        {buttonText}
      </Button>
    </Fade>
  </section>
)

QuoteSection.propTypes = {
  quote: PropTypes.shape({
    user: PropTypes.string,
    jobDescription: PropTypes.string,
    text: PropTypes.shape({ internal: PropTypes.shape({ content: PropTypes.string }) }),
  }).isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
}

export default QuoteSection
