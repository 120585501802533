import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import config from 'react-reveal/globals'
import classNames from 'classnames'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import Fade from 'react-reveal/Fade'

import { SliderNav } from '_atoms'
import {
  CookieConsentBar,
  SkyroamContainer,
  SkyroamImage,
  SkyroamChallenge,
  SkyroamChallengeContainer,
  SkyroamPlatform,
  SkyroamServer,
  SkyroamCurrency,
  SkyroamMarketing,
  SkyroamIntegration,
  SkyroamResults,
  ProudCases,
  TinySlider,
  Quote,
} from '_molecules'
import { AppContext } from '_context'
import { Navbar, Footer, ViewportObserver, SEO } from '_organisms'
import variables from '_config/css-variables'

import styles from './styles.module.css'

const TESTIMONIALS_SLIDER_OPTIONS = {
  controls: false,
  mouseDrag: true,
  loop: true,
  center: true,
  autoplay: true,
  autoplayHoverPause: true,
  autoplayTimeout: 8000,
  autoplayText: ['', ''],
  navContainer: '#testimonials-nav',
}

const SkyroamPortfolio = ({
  children,
  className,
  navbarColorOverride,
  pageTitle,
  pageDescription,
  pageThumbnailUrl,
  heroDescription,
  heroImage,
  heroImageAlt,
  challengeTitle,
  challengeDescription,
  platformTitle,
  platformDescription,
  platformImage,
  platformImageAlt,
  serverTitle,
  serverDescription,
  currencyTitle,
  currencyDescription,
  currencyImage,
  currencyImageAlt,
  marketingTitle,
  marketingDescription,
  marketingImage,
  marketingImageAlt,
  integrationTitle,
  integrationDescription,
  integrationImage,
  integrationImageAlt,
  testimonials,
  workingTogether,
  sales,
  seriesC,
  info,
  proudCases,
}) => {
  const { setNavbarDefaults } = useContext(AppContext)
  const { setNavbarColor, setNavbarLight } = useContext(AppContext)
  const [y, setY] = useState(0)
  config({ ssrFadeout: true })

  useScrollPosition(
    ({ currPos }) => {
      setY(currPos.y * -1)
    },
    [],
    null,
    false,
    300
  )

  useEffect(() => {
    return setNavbarDefaults
  }, [setNavbarDefaults])

  return (
    <div className={classNames('app', className)}>
      <Navbar navbarColorOverride={navbarColorOverride} />
      <SEO
        title={pageTitle || 'Web &amp; Mobile App Design and Development Company'}
        description={pageDescription || 'Web &amp; Mobile App Design and Development Company'}
        thumbnail={pageThumbnailUrl}
      />
      <main className={styles.hero}>
        <SkyroamContainer description={heroDescription} />
        <SkyroamImage image={heroImage} alt={heroImageAlt} />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['skyroam-primary'])
            setNavbarLight(false)
          }}
        />
      </main>
      <section className={styles.challenge}>
        <SkyroamChallenge />
        <SkyroamChallengeContainer title={challengeTitle} description={challengeDescription} />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['skyroam-gray'])
            setNavbarLight(true)
          }}
        />
      </section>
      <section className={styles.platform}>
        <SkyroamPlatform
          title={platformTitle}
          description={platformDescription}
          image={platformImage}
          alt={platformImageAlt}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['skyroam-secondary'])
            setNavbarLight(false)
          }}
        />
      </section>
      <section className={styles.server}>
        <SkyroamServer title={serverTitle} description={serverDescription} />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['skyroam-gray'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={styles.currency}>
        <SkyroamCurrency
          title={currencyTitle}
          description={currencyDescription}
          image={currencyImage}
          alt={currencyImageAlt}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['skyroam-primary'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.marketing}>
        <SkyroamMarketing
          title={marketingTitle}
          description={marketingDescription}
          image={marketingImage}
          alt={marketingImageAlt}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['skyroam-gray'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={styles.integration}>
        <SkyroamIntegration
          title={integrationTitle}
          description={integrationDescription}
          image={integrationImage}
          alt={integrationImageAlt}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['skyroam-secondary'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.testimonials}>
        <div className={styles.testimonialsContainer}>
          <Fade distance="10%" bottom>
            <TinySlider id="testimonials" options={TESTIMONIALS_SLIDER_OPTIONS}>
              {testimonials.map(quote => (
                <div key={`home-quote-${quote.id}`}>
                  <Quote
                    mobileComplete
                    color="skyroamSecondary"
                    text={quote.text.internal.content}
                    user={quote.user}
                    userPhoto={quote.userImage.fixed}
                    userJob={quote.jobDescription}
                    imageClassName={styles.testimonialsImage}
                  />
                </div>
              ))}
            </TinySlider>
            <div id="testimonials-nav" className={styles.testimonialsIcons}>
              {testimonials.map(({ id }) => (
                <SliderNav key={id} className={styles.testimonialsNav} />
              ))}
            </div>
          </Fade>
        </div>
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-white'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={styles.results}>
        <SkyroamResults
          workingTogether={workingTogether}
          sales={sales}
          seriesC={seriesC}
          info={info}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['skyroam-primary'])
            setNavbarLight(false)
          }}
        />
      </section>

      <div className={styles.portfolio}>
        <ProudCases currentProject="Skyroam" caseStudies={proudCases} extendedCTA />

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-white'])
            setNavbarLight(true)
          }}
        />
      </div>

      {children}
      <CookieConsentBar />
      <Footer />
    </div>
  )
}

SkyroamPortfolio.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  navbarColorOverride: PropTypes.string,
  pageTitle: PropTypes.string.isRequired,
  pageDescription: PropTypes.string.isRequired,
  pageThumbnailUrl: PropTypes.string.isRequired,
  heroDescription: PropTypes.string.isRequired,
  heroImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  heroImageAlt: PropTypes.string.isRequired,
  challengeTitle: PropTypes.string.isRequired,
  challengeDescription: PropTypes.string.isRequired,
  platformTitle: PropTypes.string.isRequired,
  platformDescription: PropTypes.string.isRequired,
  platformImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  platformImageAlt: PropTypes.string.isRequired,
  serverTitle: PropTypes.string.isRequired,
  serverDescription: PropTypes.string.isRequired,
  currencyTitle: PropTypes.string.isRequired,
  currencyDescription: PropTypes.string.isRequired,
  currencyImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  currencyImageAlt: PropTypes.string.isRequired,
  marketingTitle: PropTypes.string.isRequired,
  marketingDescription: PropTypes.string.isRequired,
  marketingImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  marketingImageAlt: PropTypes.string.isRequired,
  integrationTitle: PropTypes.string.isRequired,
  integrationDescription: PropTypes.string.isRequired,
  integrationImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  integrationImageAlt: PropTypes.string.isRequired,
  testimonials: PropTypes.arrayOf(PropTypes.object).isRequired,
  workingTogether: PropTypes.string.isRequired,
  sales: PropTypes.string.isRequired,
  seriesC: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  proudCases: PropTypes.arrayOf(PropTypes.object).isRequired,
}

SkyroamPortfolio.defaultProps = {
  className: undefined,
  navbarColorOverride: undefined,
  children: undefined,
}

export default SkyroamPortfolio
