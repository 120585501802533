import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import config from 'react-reveal/globals'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import { Footer, Navbar, SEO, ViewportObserver } from '_organisms'
import SEO_VARIABLES from '_config/seo-variables'
import { AppContext } from '_context'
import variables from '_config/css-variables'
import {
  ProudCases,
  QuiverQuantAbout,
  QuiverQuantChallenge,
  QuiverQuantSimplified,
  QuiverQuantFavorite,
  QuiverQuantTestmonials,
  QuiverQuantResults,
  QuiverQuantNotifications,
} from '_molecules'

import styles from './styles.module.css'

const QuiverQuantStudyCase = ({
  pageTitle,
  pageDescription,
  pageBackgroundImage,
  proudCases,
  logo,
  aboutDescription,
  cellphones,
  secondSectionText1,
  secondSectionImgGroup,
  secondSectionTitleText,
  thirdSectionText,
  thirdSectionTitleText,
  thirdSectionBackgroundImg,
  thirdSectionFrontCellphone,
  thirdSectionBackgroundImgMobile,
  fourthSectionCellphone1,
  fourthSectionCellphone2,
  fourthSectionText1,
  fourthSectionText2,
  fourthSectionTitleText1,
  fourthSectionTitleText2,
  sixthStripeText1,
  sixthStripeImg1,
  sixthStripeImg2,
  sixthStripe,
  sixthStripeText2,
  testimonials,
  logoDescription,
  cellphoneDescription,
  secondSectionImgGroupDescription,
  thirdSectionFrontCellphoneDescription,
  fourthSectionCellphone1Description,
  fourthSectionCellphone2Description,
  sixthStripeImg1Description,
  sixthStripeImg2Description,
}) => {
  const { setNavbarDefaults } = useContext(AppContext)
  const { setNavbarColor, setNavbarLight } = useContext(AppContext)
  const [y, setY] = useState(0)
  config({ ssrFadeout: true })

  useScrollPosition(
    ({ currPos }) => {
      setY(currPos.y * -1)
    },
    [],
    null,
    false,
    300
  )
  useEffect(() => {
    return setNavbarDefaults
  }, [setNavbarDefaults])

  return (
    <div className={styles.container}>
      <Navbar navbarColorOverride={variables['quiver-light-greyish']} opacityBg />
      <SEO
        title={pageTitle || SEO_VARIABLES.title}
        description={pageDescription || SEO_VARIABLES.description}
        thumbnail={pageBackgroundImage}
      />
      <section className={styles.section}>
        <QuiverQuantAbout
          cellphones={cellphones}
          aboutDescription={aboutDescription}
          logo={logo}
          logoDescription={logoDescription}
          cellphoneDescription={cellphoneDescription}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['quiver-light-greyish'])
            setNavbarLight(false)
          }}
        />
      </section>
      <section className={styles.section} id="secondSection">
        <QuiverQuantChallenge
          secondSectionTitleText={secondSectionTitleText}
          secondSectionText1={secondSectionText1}
          secondSectionImgGroup={secondSectionImgGroup}
          secondSectionImgGroupDescription={secondSectionImgGroupDescription}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-white'])
            setNavbarLight(true)
          }}
        />
      </section>
      <section className={styles.section}>
        <QuiverQuantSimplified
          thirdSectionTitleText={thirdSectionTitleText}
          thirdSectionText={thirdSectionText}
          thirdSectionBackgroundImg={thirdSectionBackgroundImg}
          thirdSectionFrontCellphone={thirdSectionFrontCellphone}
          thirdSectionBackgroundImgMobile={thirdSectionBackgroundImgMobile}
          thirdSectionFrontCellphoneDescription={thirdSectionFrontCellphoneDescription}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['quiver-light-greyish'])
            setNavbarLight(false)
          }}
        />
      </section>
      <section className={styles.section}>
        <QuiverQuantFavorite
          fourthSectionCellphone1Description={fourthSectionCellphone1Description}
          fourthSectionCellphone1={fourthSectionCellphone1}
          fourthSectionText1={fourthSectionText1}
          fourthSectionTitleText1={fourthSectionTitleText1}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['quiver-water-greenish'])
            setNavbarLight(false)
          }}
        />
      </section>
      <section className={styles.section}>
        <QuiverQuantNotifications
          fourthSectionCellphone2Description={fourthSectionCellphone2Description}
          fourthSectionCellphone2={fourthSectionCellphone2}
          fourthSectionText2={fourthSectionText2}
          fourthSectionTitleText2={fourthSectionTitleText2}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['quiver-light-greyish'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.section}>
        <QuiverQuantTestmonials testimonials={testimonials} />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-white'])
            setNavbarLight(true)
          }}
        />
      </section>
      <section className={styles.section}>
        <QuiverQuantResults
          sixthStripeText1={sixthStripeText1}
          sixthStripeImg1={sixthStripeImg1}
          sixthStripeImg2={sixthStripeImg2}
          sixthStripe={sixthStripe}
          sixthStripeText2={sixthStripeText2}
          sixthStripeImg1Description={sixthStripeImg1Description}
          sixthStripeImg2Description={sixthStripeImg2Description}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['quiver-light-greyish'])
            setNavbarLight(false)
          }}
        />
      </section>
      <section className={styles.section}>
        <ProudCases currentProject="quiverquantitative" caseStudies={proudCases} extendedCTA />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-white'])
            setNavbarLight(true)
          }}
        />
      </section>
      <Footer />
    </div>
  )
}

QuiverQuantStudyCase.propTypes = {
  sixthStripeImg1Description: PropTypes.string.isRequired,
  sixthStripeImg2Description: PropTypes.string.isRequired,
  fourthSectionCellphone1Description: PropTypes.string.isRequired,
  fourthSectionCellphone2Description: PropTypes.string.isRequired,
  thirdSectionFrontCellphoneDescription: PropTypes.string.isRequired,
  secondSectionImgGroupDescription: PropTypes.string.isRequired,
  cellphoneDescription: PropTypes.string.isRequired,
  logoDescription: PropTypes.string.isRequired,
  pageBackgroundImage: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  pageDescription: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  aboutDescription: PropTypes.string.isRequired,
  cellphones: PropTypes.string.isRequired,
  secondSectionTitleText: PropTypes.string.isRequired,
  secondSectionText1: PropTypes.string.isRequired,
  secondSectionImgGroup: PropTypes.string.isRequired,
  thirdSectionTitleText: PropTypes.string.isRequired,
  thirdSectionText: PropTypes.string.isRequired,
  thirdSectionBackgroundImg: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  thirdSectionFrontCellphone: PropTypes.string.isRequired,
  thirdSectionBackgroundImgMobile: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  fourthSectionCellphone1: PropTypes.string.isRequired,
  fourthSectionCellphone2: PropTypes.string.isRequired,
  fourthSectionText1: PropTypes.string.isRequired,
  fourthSectionText2: PropTypes.string.isRequired,
  fourthSectionTitleText1: PropTypes.string.isRequired,
  fourthSectionTitleText2: PropTypes.string.isRequired,
  sixthStripeText1: PropTypes.string.isRequired,
  sixthStripeImg1: PropTypes.string.isRequired,
  sixthStripeImg2: PropTypes.string.isRequired,
  sixthStripe: PropTypes.string.isRequired,
  sixthStripeText2: PropTypes.string.isRequired,
  testimonials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  proudCases: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default QuiverQuantStudyCase
