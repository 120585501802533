import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

import ThawSettingsBackground from '_images/pages/portfolio/thaw/thaw-settings-bg.png'
import { Image, Text, Container } from '_atoms'

import styles from './styles.module.css'

const ThawSettings = ({ title, description, image, backgroundImage }) => {
  // eslint-disable-next-line no-console
  console.log(backgroundImage.description)
  return (
    <Container className={styles.settingsContainer}>
      <Image
        src={ThawSettingsBackground}
        className={styles.settingsBG}
        alt={backgroundImage.description}
      />
      <div className={styles.settingsBGMask}>
        <div className={styles.settingsContentWrapper}>
          <div className={styles.settingsRow}>
            <div className={styles.settingsContent}>
              <Fade distance="10%" bottom>
                <Text size="48" bold className={styles.settingsTitle}>
                  {title}
                  <span className={styles.dotSecondary}>.</span>
                </Text>
              </Fade>
              <div className={styles.settingsDescriptionWrapper}>
                <Fade delay={200} distance="10%" bottom>
                  <Text size="22" splitParagraphs className={styles.settingsDescription}>
                    {description}
                  </Text>
                </Fade>
              </div>
            </div>
            <div className={styles.settingsImage}>
              <Fade delay={450} distance="20%" bottom>
                <Img
                  fluid={image}
                  imgStyle={{
                    objectFit: 'contain',
                    position: 'relative',
                  }}
                  style={{ top: 0 }}
                  fadeIn={false}
                />
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

ThawSettings.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  backgroundImage: PropTypes.shape({
    description: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      sizes: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcSetWebp: PropTypes.string,
      srcWebp: PropTypes.string,
    }),
  }).isRequired,
}

export default ThawSettings
